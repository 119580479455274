<template>
    <div ref="colorpicker" class="input-group color-picker">
        <input
            v-model="colorValue"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': errorBorder }"
            @focus="showPicker"
            @input="updateFromInput"
        />
        <span class="input-group-addon color-picker-container">
            <span
                class="current-color"
                :style="'height: 100%; background-color: ' + colorValue"
                @click="togglePicker"
            />
            <chrome-picker
                v-if="displayPicker"
                :value="colors"
                @input="updateFromPicker"
            />
        </span>
    </div>
</template>

<script>
import { Chrome } from 'vue-color';

export default {
    components: {
        'chrome-picker': Chrome
    },

    props: {
        color: {
            type: String,
            required: false,
            default: ''
        },
        errorBorder: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            colors: {
                hex: '#000000'
            },
            colorValue: '',
            displayPicker: false
        };
    },
    watch: {
        colorValue(val) {
            if (val) {
                this.updateColors(val);
                this.$emit('input', val);
            }
        }
    },
    mounted() {
        this.setColor(this.color);
    },
    methods: {
        setColor(color) {
            if (!color) {
                return;
            }

            this.updateColors(color);
            this.colorValue = this.colors.hex;
        },
        updateColors(color) {
            if (color.slice(0, 1) == '#') {
                this.colors = {
                    hex: color
                };
            } else if (color.slice(0, 4) == 'rgba') {
                const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
                const hex =
                    '#' +
                    (
                        (1 << 24) +
                        (parseInt(rgba[0]) << 16) +
                        (parseInt(rgba[1]) << 8) +
                        parseInt(rgba[2])
                    )
                        .toString(16)
                        .slice(1);

                this.colors = {
                    hex: hex,
                    a: rgba[3]
                };
            } else {
                this.colors = {
                    hex: '#' + color
                };
            }
        },
        showPicker() {
            document.addEventListener('click', this.documentClick);
            this.displayPicker = true;
        },
        hidePicker() {
            document.removeEventListener('click', this.documentClick);
            this.displayPicker = false;
        },
        togglePicker() {
            this.displayPicker ? this.hidePicker() : this.showPicker();
        },
        updateFromInput() {
            this.updateColors(this.colorValue);
        },
        updateFromPicker(color) {
            this.colors = color;

            if (color.rgba.a == 1) {
                this.colorValue = color.hex;
            } else {
                this.colorValue = color.hex8;
            }
        },
        documentClick(e) {
            const el = this.$refs.colorpicker;
            const target = e.target;

            if (el !== target && !el.contains(target)) {
                this.hidePicker();
            }
        }
    }
};
</script>

<style>
h1 {
    height: 120px;
    line-height: 120px;
    text-align: center;
}

.vc-chrome {
    position: absolute;
    top: 35px;
    right: 0;
    z-index: 9;
}

.current-color {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: #000;
    cursor: pointer;
    border: 1px solid #ced4da;
    border-left: 0;
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
}

.footer {
    margin-top: 20px;
    text-align: center;
}

.vc-chrome-fields-wrap {
    display: none !important;
}
</style>
